<template>
    <div class="service-charge-wrap bg-stratos pt-20 pb-20" >
        <div class="container">
            <div class="section-title style1 text-center mb-40">
                <span>SIMULATEUR</span>
                <h2 class="text-white"> votre simulation</h2>
            </div>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="estimationPret-tab" data-bs-toggle="tab" data-bs-target="#estimationPret" 
                    type="button" role="tab" aria-controls="estimationPret" aria-selected="true">Estimation d'envoi de colis </button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="estimationPret" role="tabpanel" aria-labelledby="estimationPret-tab">
                    <div class="row pt-50" id="">
                            <div class="col-lg-7">
                                <Form @submit="checkEstimation" :validation-schema="estimationSchema" class="charge-form">
                                    <div class="row justify-content-center mb-10">
                                        <div class="col-lg-4 col-md-4">
                                            <div class="form-group">
                                                <label for="send_money">TYPE D'ENVOI</label>
                                                <Field name="type" v-slot="{ field }" class="form-control shadow-none fs-md-15 text-dark" style="background-color: black; font-weight: bold;">
                                                    <Multiselect style="color: black; font-weight: bold;"
                                                        :options="typeValue"
                                                        :searchable="true"
                                                        track-by="label"
                                                        label="label"
                                                        v-model = "field.value"
                                                        v-bind = "field"
                                                        @change="typeChange($event)"
                                                        placeholder="Sélectionner le type"
                                                    />
                                                </Field>
                                            </div>
                                            <ErrorMessage name="type" class="text-danger" style="font-weight: bold;"/>
                                        </div>
                                        <div class="col-lg-8 col-md-8">
                                            <div class="form-group">
                                                <label for="recieved_money">DISTANCE</label>
                                                <Field name="distance" v-slot="{ field }" style="background-color: black" class="form-control shadow-none fs-md-15 text-white">
                                                <Multiselect style="color: black; font-weight: bold;"
                                                    :options="distanceOptions"
                                                    :searchable="true"
                                                    track-by="label"
                                                    label="label"
                                                    v-model="distanceSelect"
                                                    v-bind="field"
                                                    noOptionsText = "Sélectionner d'abord un type d'envoi"
                                                    placeholder="Sélectionner la distance"
                                                />
                                                </Field>
                                            </div>
                                            <ErrorMessage name="distance" class="text-danger" style="font-weight: bold;"/> <br>
                                        </div>
                                        <div class="col-lg-4 col-md-4">
                                            <div class="form-group">
                                                <label for="recieved_money">POIDS DU COLIS</label>
                                                <Field name="poids" type="number" id="recieved_money" class="form-control shadow-none fs-md-15 text-black" style="font-weight: bold;background-color: white;"
                                                    placeholder="en g" />
                                            </div>
                                            <ErrorMessage name="poids" class="text-danger" style="font-weight: bold;"/>
                                        </div>
                                        <div class="col-lg-8 col-md-8 ">
                                            <div class="form-group">
                                                <label for="recieved_money" ></label>
                                                <button type="submit" class="btn style1 w-100 mt-3"
                                                style="font-size: 17px !important;font-weight: bold !important;">
                                                    Obtenir l'estimation
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <div class="col-lg-5" v-if="type || poids || distance">
                                <div class="card" style="background-color: #00519e; color: white !important; border-color: white;">
                                    <h3 class="texte-center" style="text-align: center; color: white;">POINT DE L'ESTIMATION</h3>
                                    <div class="card-body ">
                                        <h6 class="card-subtitle mb-3 text-white">TYPE D'ENVOI : <span style="color: #fec329; float: right;"> {{ type == 1 ? "Colis postaux" : type == 2 ? "Envois express" : type == 3 ? "Poste aux lettres" : type == 4 ? "Transfert et Location" : "" }}</span></h6>
                                        <h6 class="card-subtitle mb-3">POIDS DU COLIS : <span style="color: #fec329; float: right;"> {{ poids}} g</span></h6>
                                        <h6 class="card-subtitle mb-3 text-white">DISTANCE :<span style="color: #fec329; float: right;  clear: both;"> {{distance}} </span></h6>
                                        <h6 class="card-subtitle mb-3">MONTANT TOTAL : <span style="color: #fec329; float: right;"> {{montant}} FCFA</span></h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5" v-else>
                                <div class="card" style="background-color: #00519e; color: white !important; border-color: white;">
                                    <div class="card-body "> </div>
                                    <h6 class="texte-center" style="text-align: center; color: white;">Ici, vous pouvez calculer les frais approximatifs pour recevoir vos colis au Bénin et partout dans le monde.
                                         Les valeurs calculées sont estimées et leur utilisation est référentielle. </h6>
                                    <div class="card-body "> </div>

                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref,watch } from 'vue';
import Multiselect from '@vueform/multiselect';
import { Form, Field, ErrorMessage } from 'vee-validate';
import ApiService from "../../services/ApiService";
import axios from 'axios';
import { error, success } from '@/utils/utils';
import { useRouter } from 'vue-router';
import * as Yup from "yup";
import { eventBus } from '@/utils/redirection';

export default {
    name: 'ChargeCalculator',
    components: {
    Form,
    Field,
    Multiselect,
    ErrorMessage,
  },
  setup: () => {

    const estimationSchema = Yup.object().shape({
      type: Yup.string().required('Le type d\'envoi est obligatoire'),
      poids: Yup.number().typeError("Entrez un poids valide").required('Le poids est obligatoire'),
      distance: Yup.string().required("La distance est obligatoire"),
    });

    const distanceOptions=ref([]);
    const distanceSelect=ref([]);
    const typeValue = 
      [
      {
          value: 1 ,
          label: "Colis postaux"
      },
      {
          value: 2 ,
          label: "Envois express"
      },
      {
          value: 3 ,
          label: "Poste aux lettres"
      },
      {
          value: 4 ,
          label: "Transport de colis/marchandises et Location de bus/camions"
      }
    ];
    const router = useRouter();

    function typeChange(value) {
    if(value){
        distanceOptions.value = [];
        distanceSelect.value=[]
        ApiService.get('/distance/type/'+value)
            .then(({ data }) => {
                const donnee = data.data;
                if(donnee.length > 0) {
                    distanceOptions.value = donnee.map((distance: any) => {
                    return {
                    label: distance.libelle + ':' + '' + distance.distance,
                    value: distance.id,
                    };
                });
                }
            })
            .catch(({ response }) => {
            //error(response.data.message);
            });
        }else {
            distanceOptions.value = [];
            distanceSelect.value=[];
        }
    }

    const montant = ref();
    const distance = ref();
    const poids = ref();
    const type = ref();
    const checkEstimation = async (values) => {
      console.log('offvale',values)
    //   ApiService.post("/montantEstimations", values)
    ApiService.post("/montantEstimations", values)
        .then(({ data }) => {
            montant.value = data.montant.montant;
            distance.value = data.distanceEntity.libelle + " : " + data.distanceEntity.distance;
            type.value = data.distanceEntity.type; 
            poids.value = data.getPoids;
            console.log('estimation',montant.value);
            console.log('distance',distance.value);
            console.log('poids',type.value);
            console.log('type',poids.value);
        //   if (data.code == 201) {
        //     success(data.message);
        //     //resetForm();
        //     //router.push({ name: "ListeProduitPage" });
        //   }
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    };

    // const showSimulation = ref(eventBus.showSimulation);

    // watch(eventBus, (newVal) => {
    //   showSimulation.value = newVal.showSimulation;
    //   console.log('charge', showSimulation.value);
    // });

    return {
        typeValue,
        distanceOptions,
        typeChange,
        distanceSelect,
        estimationSchema,
        checkEstimation,
        montant,distance,
        poids,type,
    }
  }
}
</script>
